<template>
  <ServiceLayout>
    <div class="md-service-1">
      <!-- 广告图 -->
      <Banner :list="bannerList" height="4.92rem" v-ani.fade-up />
      <!-- 流程 -->
      <ServiceProcess :info="info" v-ani.fade-up />
      <!-- 提供服务 -->
      <ServiceVS :data1='data1' :data2='data2' v-ani.fade-up />
      <!-- 客户案例 -->
      <ServiceClientCase title="客户案例" :list='list' />
    </div>
  </ServiceLayout>
</template>

<script>
import { IndexBannerApi } from "@/request/api/user";

export default {
  created() {
    // 轮播图
    IndexBannerApi({ from: 425 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerList = data;
      }
    })
  },
  data() {
    return {
      // 轮播图列表
      bannerList: [
        // {
        //   id: 1,
        //   link: '',
        //   btn: false,
        //   target: '',
        //   img: require('@/assets/images/svc2_0.jpg'),
        //   title: '工程资料',
        //   intro: '签证，结算书，变更资料，施工合同'
        // },
      ],
      info: {
        title: '工程资料',
        desc: '项目全程中形成的文件、图纸、计算和声像等信息，对保证工程顺利和资料完整至关重要。资料形式多样，可按性质分类。遵守管理规定，确保完整和准确。',
        list: [
          { id: 1, title: '建立资料<br>管理体系' },
          { id: 2, title: '资料收集' },
          { id: 3, title: '资料整理<br>和分类' },
          { id: 4, title: '资料保存<br>和备份' },
          { id: 5, title: '资料传递<br>和借阅' },
          { id: 6, title: '资料归档<br>和移交' },
        ]
      },
      data1: {
        title: '提供的服务',
        list: [
          {
            id: 1,
            icon: require('@/assets/images/svc2_5.png'),
            tit: '资料收集',
            desc: '整理和归纳各种资料<br>确保资料完整性和准确性',
          }, {
            id: 2,
            icon: require('@/assets/images/svc2_6.png'),
            tit: '资料归档',
            desc: '进行分类、编目、归档，建立完善的档案管理体',
          }, {
            id: 3,
            icon: require('@/assets/images/svc2_7.png'),
            tit: '资料更新',
            desc: '根据实际施工进度，及时更新资料确保资料的实时性和有效性',
          }, {
            id: 4,
            icon: require('@/assets/images/svc2_8.png'),
            tit: '资料审核',
            desc: '严格的审核和把关、确保资料的准确性和合规性',
          }
        ]
      },
      data2: {
        title: '面对的困难',
        list: [
          {
            id: 1,
            icon: require('@/assets/images/svc2_9.png'),
            tit: '资料混乱',
            desc: '导致项目资料混乱，无法清晰地了解项目的整体情况，影响项目决策和实施',
          }, {
            id: 2,
            icon: require('@/assets/images/svc2_10.png'),
            tit: '资料丢失',
            desc: '造成项目资料的丢失或遗漏，影响项目的进度和质量',
          }, {
            id: 3,
            icon: require('@/assets/images/svc2_11.png'),
            tit: '成本增加',
            desc: '没有专业的工程工程资料服务，投入更多的人力和时间来整理资料',
          }, {
            id: 4,
            icon: require('@/assets/images/svc2_12.png'),
            tit: '风险管理困难',
            desc: '影响项目的风险管理，无法及时发现和解决潜在问题。',
          }
        ]
      },
      list: [
        {
          id: 1,
          img: require('@/assets/images/svc2_1.jpg'),
          tit: '项目全程中形成的文件、图纸、计算和声像',
          date: '2023/11/27'
        }, {
          id: 2,
          img: require('@/assets/images/svc2_2.jpg'),
          tit: '项目全程中形成的文件、图纸、计算和声像',
          date: '2023/11/27'
        }, {
          id: 3,
          img: require('@/assets/images/svc2_3.jpg'),
          tit: '项目全程中形成的文件、图纸、计算和声像',
          date: '2023/11/27'
        }, {
          id: 4,
          img: require('@/assets/images/svc2_4.jpg'),
          tit: '项目全程中形成的文件、图纸、计算和声像',
          date: '2023/11/27'
        },
      ]
    };
  },
};
</script>

<style lang="scss" scoped></style>